import React, { Component } from 'react'
import Seo from '../components/seo'
import Link from '../utils/link'
import ContactForm from '../components/contact-form'
import Logo from '../assets/images/logo.svg'
import Banner from '../assets/images/banner.jpg'
import Image1 from '../assets/images/image-1.jpg'
import Image2 from '../assets/images/image-2.jpg'
import Map from '../assets/images/map.jpg'
import Fade from 'react-reveal/Fade'

class Home extends Component {

  render() {

    return (
      <>
        <Seo title="I&D Group" description="Concrete Structures" />
        <section className='banner'>
          <div className='banner__inner'>
            <img className='banner__logo' src={Logo} alt='I&D Group' />
            <img className='banner__bg' src={Banner} alt='I&D Group' />
          </div>
        </section>
        <section className='basic'>
          <Fade bottom distance='40px'>
            <div className='basic__inner'>
              <div className='basic__content'>
                <p>I&amp;D Group are regarded as one of the largest deliverers of formwork solutions in Victoria. I&D partner with large-scale building and construction companies in the private and public markets on projects large and small.</p>
                <button className='btn' onClick={() => document && document.querySelector('#contact').scrollIntoView({behaviour: 'smooth'})}>Contact</button>
              </div>
            </div>
          </Fade>
        </section>
        <section className='image-content'>
          <Fade bottom distance='40px'>
            <div className='image-content__inner'>
              <div className='image-content__image'>
                <img src={Image1} alt='I&D Group - Company Profile' />
              </div>
              <div className='image-content__content'>
                <h3>Company Profile</h3>
                <p>I&D Group was formed in 1994 by Ivan and Kathy Filipovic. Their passion and commitment to getting the job done safely and economically has underpinned their success in the industry. From humble beginnings, I&D now employs more than 700 dedicated professional and support staff involved in a number of landmark projects across Victoria.</p>
                <p>With our corporate headquarters and storage yard based in the southeast suburbs, we have at our disposal thousands of square metres of quality materials along with state of the art lifting and crane equipment, enabling us to deliver the largest projects.</p>
                <p>We are committed to delivering high quality projects by partnering with our clients and collaborating with project suppliers to ensure each job is delivered to exceed expectations.</p>
              </div>
            </div>
          </Fade>
        </section>
        <section className='image-content'>
          <Fade bottom distance='40px'>
            <div className='image-content__inner'>
              <div className='image-content__image'>
                <img src={Image2} alt='I&D Group - Capabilities' />
              </div>
              <div className='image-content__content'>
                <h3>Capabilities</h3>
                <p>We specialise in providing complete concrete structure from start to end, including:</p>
                <ul>
                  <li>Formwork,</li>
                  <li>Reinforcement,</li>
                  <li>Post tensioning,</li>
                  <li>Concrete, Pumping,</li>
                  <li>Precast Concrete Supply & Erection,</li>
                  <li>Tower & Mobile Cranage,</li>
                  <li>Temporary & Permanent Works Design</li>
                </ul>
                <p>I&D use a number of different formwork systems depending on the requirements and scope of the project.</p>
                <ul>
                  <li>Table forms,</li>
                  <li>Formdeck metal decking,</li>
                  <li>Pan Systems for flat plate slabs,</li>
                  <li>Hydraulic and Crane lifted jump forms,</li>
                  <li>Hydraulic & Crane lifted protection screens.</li>
                </ul>
                <p>I&D owns and manufactures all of its product locally, at its plant in the South Eastern suburbs of Melbourne. I&D utilizes the latest in design and innovation to ensure they are the leaders within the industry.</p>
              </div>
            </div>
          </Fade>
        </section>
        <section className="contact" id="contact">
          <Fade bottom distance='40px'>
            <div className='contact__inner'>
              <div className='contact__content'>
                <h3>Contact Details</h3>
                <p>100 York Street, South Melbourne 3205</p>
                <ul>
                  <li><span>Telephone:</span><Link to='tel=+61395601812'>(03) 9560 1812</Link></li>
                  <li><span>Facsimile:</span><Link to='tel=+61395601912'>(03) 9560 1912</Link></li>
                  <li><span> General email:</span><Link to='mailto:info@id-group.net.au'>info@id-group.net.au</Link></li>
                  <li><span>Resumes:</span><Link to='mailto:careers@id-group.net.au'>careers@id-group.net.au</Link></li>
                </ul>
                <Link className='contact__map' to='https://www.google.com/maps/place/100+York+St,+South+Melbourne+VIC+3205/data=!4m2!3m1!1s0x6ad667ff9d8acd9f:0x54a84ac18077184c?sa=X&ved=2ahUKEwiUmJ-g2rn5AhXmALcAHQL3A5sQ8gF6BAgPEAE'>
                  <img src={Map} alt='I&D Group - Contact' />
                </Link>
              </div>
              <div className='contact__form'>
                <ContactForm />
              </div>
            </div>
          </Fade>
        </section>
      </>
    )
  }
}

export default Home